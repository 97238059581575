import React from 'react';
import PreviewDocumentDataWrapper from "./PreviewDocumentDataWrapper";
import { Box } from '@mui/material';

const PreviewDocumentHome = () => {
  return (
    <Box className={"h-100 w-100"}>
      <Box className={"h-100 w-100"}>
        <PreviewDocumentDataWrapper />
      </Box>
    </Box>
  );
};

export default PreviewDocumentHome;
