import React from 'react';
import { globalRoot } from "../../../utils/defaultValues";
import IntlMessages from "../../../utils/IntlMessages";
import DocumentScannerSharpIcon from '@mui/icons-material/DocumentScannerSharp';
import PreviewDocumentHome from './PreviewDocumentHome';

const PreviewDocuemntRouteConfig = {
	routes: [
		{
    id:"preview-document",
    path: `${globalRoot}/preview-document`,
    exact: true,
    showBreadCrumbs: true,
    name: <IntlMessages id={"menu.preview-document"}/>,
    element: <PreviewDocumentHome/>,
    icon: <DocumentScannerSharpIcon style={{ width:22, height:22, color: "#637381"}} />,
    auth: [],
    isParent: true,
    showBreadCrumbs: true,
    children: []

  },
	]
};
export default PreviewDocuemntRouteConfig;