import { Navigate, useRoutes, redirect } from "react-router-dom";
import ProtectedRoute from "../authentication/ProtectedRoute";
import Layout from "../layout/app-container/Layout";
import { globalRoot } from "../utils/defaultValues";
import UserManagementRouteConfig from "../guru-suite/user-management/UserManagementRouteConfig";
import EsgModuleRouteConfig from "../guru-suite/esg-module/EsgModuleRouteConfig";
import ProjectmonitoringModuleRouteConfig from "../guru-suite/projectmonitoring-module/ProjectmonitoringModuleRouteConfig";
import TamModuleRouteConfig from "../guru-suite/tam-module/TamModuleRouteConfig";
import TddModuleRouteConfig from "../guru-suite/tdd-module/TddModuleRouteConfig";
import UserDashBoardRouteConfig from "../user-dashboard/UserDashBoardRouteConfig";
import TaskManagementRouteConfig from "../guru-suite/task-management/TaskManagementRouteConfig";
import Login from "../authentication/Login";
import ForgotPassword from "../authentication/ForgotPasswordPage";
import ResetPassword from "../authentication/ResetPassword";
import IntlMessages from "../utils/IntlMessages";
import CostDataRouteConfig from "../guru-suite/cost-data-room/CostDataRouteConfig";
import HomeIcon from "@mui/icons-material/Home";
import ClientManagementRouteConfig from "../guru-suite/client-management/ClientManagementRouteConfig";
import DataCollectionRouteConfig from "../guru-suite/data-collection/DataCollectionRouteConfig";
import CategoryIcon from '@mui/icons-material/Category';
import DocumentBuilderRouteConfig from "../guru-suite/document-builder/DocumentBuilderRouteConfig"
import CostDataCenterRouteConfig from "../guru-suite/cost-data-center/CostDataCenterRouteConfig";
import PreviewDocuemntRouteConfig from "../guru-suite/preview-document/structure/PreviewDocumentRouteConfig";

const AppRouter = () => {
  const defaultRoute = [
    {
      path: `/`,
      id: "guru-home",
      showBreadCrumbs: true,
      name: <IntlMessages id="menu.home" />,
      icon: <HomeIcon />,
      element: <Navigate to={`${globalRoot}`} />,
    },
    {
      path: `${globalRoot}`,
      id: "guru-home",
      showBreadCrumbs: true,
      name: <IntlMessages id="menu.home" />,
      icon: <HomeIcon />,
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        ...UserManagementRouteConfig.routes.map((u) => u),
        ...EsgModuleRouteConfig.routes.map((e) => e),
        ...ProjectmonitoringModuleRouteConfig.routes.map((e) => e),
        ...TamModuleRouteConfig.routes.map((e) => e),
        ...TddModuleRouteConfig.routes.map((e) => e),
        ...UserDashBoardRouteConfig.routes.map((e) => e),
        ...TaskManagementRouteConfig.routes.map((e) => e),
        ...CostDataRouteConfig.routes.map((e) => e),
        ...ClientManagementRouteConfig.routes.map((e) => e),
        ...DataCollectionRouteConfig.routes.map((e) => e),
        ...DocumentBuilderRouteConfig.routes.map((e) => e),
        ...CostDataCenterRouteConfig.routes.map((e) => e),
        ...PreviewDocuemntRouteConfig.routes.map((e) => e),
      ],
    },
    {
      path: "/login",
      element: <Login />,
      children: [],
    },
    {
      path: "/login/forgot-password",
      element: <ForgotPassword />,
      children: [],
    },
    {
      path: "/login/reset-password",
      element: <ResetPassword />,
      children: [],
    },
  ];
  const routes = useRoutes(defaultRoute);
  return routes;
};

export const getRoutes = () => {
  const defaultRoute = [
    {
      path: `${globalRoot}/`,
      id: "guru-home",
      showBreadCrumbs: true,
      element: <Layout />,
      name: <IntlMessages id="menu.home" />,
      icon: (
        <HomeIcon
          style={{ width: 15, height: 15, color: "#244a9a", marginRight: 5 }}
        />
      ),
      children: [],
    },
    {
      path: `${globalRoot}`,
      id: "guru-home",
      showBreadCrumbs: true,
      element: <Layout />,
      icon: (
        <HomeIcon
          style={{ width: 15, height: 15, color: "#244a9a", marginRight: 5 }}
        />
      ),
      children: [],
    },
  ];

  return [
    ...defaultRoute,
    ...UserManagementRouteConfig.routes.map((u) => u),
    ...EsgModuleRouteConfig.routes.map((e) => e),
    ...ProjectmonitoringModuleRouteConfig.routes.map((e) => e),
    ...ProjectmonitoringModuleRouteConfig.routes.map((e) => e.children),
    ...TamModuleRouteConfig.routes.map((e) => e),
    ...UserDashBoardRouteConfig.routes.map((e) => e),
    ...TddModuleRouteConfig.routes.map((e) => e),
    ...TddModuleRouteConfig.routes.map((e) => e.children),
    ...ClientManagementRouteConfig.routes.map((e) => e),
    ...DataCollectionRouteConfig.routes.map((e) => e),
    ...DocumentBuilderRouteConfig.routes.map((e) => e),
    ...CostDataCenterRouteConfig.routes.map((e) => e),
    ...PreviewDocuemntRouteConfig.routes.map((e) => e),
  ].flat(1);
};

export default AppRouter;
