export const riskTypeOptions = {
    HIGH: 'hoch', 
    MEDIUM: 'mittel',
    LOW: 'niedrig',
}

/* Preview Document export functions */
export const MAX_NAME_LENGTH = 38;

export const NON_PARSABLE_FILES = [
    '20231120_weka-sirados_regionalfaktoren.pdf',
    'AHO Nr. 28 Leistungen für Fassadentechnik_September 2021.pdf',
    '1.5.3.3 Erläuterungsbericht Technische Gebäudeausrüstung_16.01.2009.pdf',
    '20230912_Vorschriften für Fluchtwege und Notausgänge - ASR A1.3 & Co.pdf',
    'AHO Nr. 17 Leistungen für Brandschutz_Dezember 2022.pdf',
    '20210921_oscar-buero-2011-auszug.pdf',
    '20240213_destatis_baupreisindizes_quartal 4 2023.pdf',
    'destatis_baupreisindex_konjunkturindikatoren_quartal 1 2024.pdf',
    '2019_bnb_leitfaden nachhaltiges bauen.pdf',
    '2024_dgnb_kriterienkatalog.pdf',
    'IPMS-All-Buildings-2023-4pjley1-r5ppw6.pdf'
];


export const truncateName = (name, maxLength = MAX_NAME_LENGTH) => 
    name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
